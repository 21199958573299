<template>
  <q-page class="page bg-white column items-center no-wrap">
    <div class="code flex items-center justify-center" />
    <div class="column container">
      <q-card square class="bg-white relative-position">
        <q-card-section :class="`bg-primary text-white text-uppercase`">
          {{ $t('where_are_you_flying') }}
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="q-col-gutter-sm">
            <div class="row">
              <div class="col-6 center">
                <q-radio v-model="returning" :val="true" label="Return" />
              </div>
              <div class="col-6 center">
                <q-radio v-model="returning" :val="false" label="One way" />
              </div>
            </div>
            <div
              class="q-col-gutter-sm content-center relative-position"
              :class="$q.platform.is.mobile ? 'row' : ''"
            >
              <div :class="$q.platform.is.desktop ? 'row' : 'col-10'">
                <div class="col-10 col-sm-5">
                  <m-select-query
                    v-model="parameters.origin"
                    :label="$t('going_from')"
                    stack-label
                    :placeholder="$t('input.departure_airport')"
                    icon="mdi-airplane-takeoff"
                    :query="autocomplete"
                    clearable
                  />
                </div>
                <div
                  v-if="$q.platform.is.desktop"
                  class="justify-center flex icon-wrap col-2"
                >
                  <q-btn
                    flat
                    class="icon"
                    size="14px"
                    :icon="
                      $q.platform.is.desktop
                        ? 'fa fa-exchange'
                        : 'fa fa-sort-alt'
                    "
                    @click="swap"
                  />
                </div>
                <div class="col-10 col-sm-5">
                  <m-select-query
                    v-model="parameters.destination"
                    :label="$t('going_to')"
                    stack-label
                    :placeholder="$t('input.arrival_airport')"
                    icon="mdi-airplane-landing"
                    :query="autocomplete"
                    clearable
                  />
                </div>
              </div>
              <div
                v-if="$q.platform.is.mobile"
                class="justify-center flex icon-wrap col-2"
              >
                <q-btn
                  flat
                  class="icon"
                  size="14px"
                  :icon="
                    $q.platform.is.desktop ? 'fa fa-exchange' : 'fa fa-sort-alt'
                  "
                  @click="swap"
                />
              </div>
            </div>

            <div class="row q-col-gutter-sm">
              <div :class="returning ? 'col-6 col-offset-1' : 'col-12'">
                <date-field
                  v-model="parameters.outbound"
                  :min="departMin"
                  :label="$t('date.travel')"
                />
              </div>
              <div v-if="returning" class="col-6">
                <date-field
                  v-model="parameters.inbound"
                  :min="parameters.outbound"
                  :label="$t('date.return')"
                />
              </div>
            </div>
            <div class="row q-col-gutter-sm">
              <div class="col-6">
                <q-select
                  v-model="parameters.class"
                  :options="cabinClasses"
                  stack-label
                  :label="$t('flight.class.cabin')"
                  map-options
                  emit-value
                />
              </div>
              <div class="col-6">
                <q-select
                  v-model="parameters.max_changes"
                  :options="changes"
                  stack-label
                  :label="$t('flight.maximum_changes')"
                  map-options
                  emit-value
                />
              </div>
            </div>
          </div>
        </q-card-section>
      </q-card>
      <div class="row">
        <div class="col">
          <m-unified-users v-model="people" />
        </div>
      </div>
      <div class="row" style="padding: 8px">
        <q-btn
          :disabled="disabled"
          size="lg"
          color="primary"
          class="full-width"
          @click="submit"
        >
          {{ $tc('find.flight', 2) }}
        </q-btn>
      </div>
    </div>
  </q-page>
</template>

<script type="text/javascript">
import { airports } from 'api/flights'
import date from 'utils/date-time'
import model from './model'
import authentication from 'mixins/authentication'
import searchLocation from 'mixins/searchLocation'
import { MSelectQuery, MUnifiedUsers, dateField } from 'components/'

export default {
  name: 'OndemandFlights',
  components: {
    dateField,
    MSelectQuery,
    MUnifiedUsers
  },
  mixins: [authentication, searchLocation],
  data () {
    return {
      departMin: date.newDate(),
      parameters: model(),
      cabinClasses: [
        {
          label: this.$t('flight.class.economy'),
          value: 'Economy'
        },
        {
          label: this.$t('flight.class.premium_economy'),
          value: 'PremiumEconomy'
        },
        {
          label: this.$t('flight.class.business'),
          value: 'Business'
        },
        {
          label: this.$t('flight.class.first'),
          value: 'First'
        }
      ],
      changes: [
        {
          label: this.$tc('flight.changes', 0),
          value: 'direct'
        },
        {
          label: this.$tc('flight.changes', 1),
          value: '1'
        },
        {
          label: this.$tc('flight.changes', 2, { n: '2+' }),
          value: null
        }
      ],
      fromListOptions: []
    }
  },
  computed: {
    people: {
      get () {
        return {
          traveller: this.parameters.traveller,
          requester: this.parameters.requester
        }
      },
      set (val) {
        this.parameters.requester = val.requester
        this.parameters.traveller = val.traveller
      }
    },
    returning: {
      get () {
        return !!this.parameters.inbound
      },
      set (value) {
        this.parameters.inbound = value
          ? date.addToDate(this.parameters.outbound, { days: 2 })
          : null
      }
    },
    locationsSet () {
      return this.parameters.origin && this.parameters.destination
    },
    disabled () {
      return !(
        this.locationsSet &&
        this.parameters.traveller &&
        this.parameters.requester
      )
    }
  },
  watch: {
    '$route.params.origin': {
      immediate: true,
      deep: true,
      handler (val) {
        if (typeof val === 'object') {
          this.parameters.origin = val
        }
      }
    },
    'parameters.outbound' (val) {
      if (val > this.parameters.inbound && this.returning) {
        this.parameters.inbound = date.newDate(val)
      }
    },
    '$route.query.origin': {
      deep: true,
      immediate: true,
      handler (val) {
        if (typeof val === 'object') {
          this.parameters.origin = val
        }
      }
    },
    '$route.query.destination': {
      deep: true,
      immediate: true,
      handler (val) {
        if (typeof val === 'object') {
          this.parameters.destination = val
        }
      }
    }
  },
  created () {
    this.$store.commit('ondemand/clear')
  },
  methods: {
    setOrigin (val) {
      this.parameters.origin = val
    },
    submit () {
      this.$store.dispatch('ondemand/stash', { parameters: this.parameters })
      this.$router.push({ name: 'ondemand-flights-outbound' })
    },
    swap () {
      [this.parameters.destination, this.parameters.origin] = [
        this.parameters.origin,
        this.parameters.destination
      ]
    },
    airports,
    async autocomplete (terms) {
      const { data } = await airports({ query: terms })
      return data.map(airport => {
        return {
          label: airport.name,
          value: airport.iata,
          icon: 'local_airport',
          color: 'red-5',
          append: airport.iata
        }
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.mobile
  .code
    display none
  .column.container
    margin 32px
  .cta
    background-color #fafafa
    padding 16px 8px
    position fixed
    left 0
    right 0
    bottom 0
    box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2), 0 5px 8px rgba(0,0,0,0.14), 0 1px 14px rgba(0,0,0,0.12);
.cta
  padding 8px
.code
  height 25vh
  width 100%
  font-size 8vmax
  color rgba(255, 255, 255, .7)
  overflow hidden
  background-image url('~assets/backgrounds/flight.png')
  background-position: cover;
  background-repeat: no-repeat;
  background-size: 115%;
  text-overflow: clip;

.column.container
  margin-top -64px

.q-card
  width 80vw
  max-width 600px

.center
  justify-content center
  display flex
</style>
